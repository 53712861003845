import * as Sentry from '@sentry/react'
import * as React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { AuthProvider } from '@/lib/auth'
import { queryClient } from '@/lib/react-query'
import { OtherContextProvider } from '@/providers/Other.context'

import { CurrencyProvider } from './Currency.context'
import { WalletProvider } from './Wallet.context'

const ErrorFallback = () => {
  return (
    <div
      className="flex h-screen w-screen flex-col items-center justify-center text-red-500"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </button>
    </div>
  )
}

export const AppProvider = ({ children }: { children: React.ReactElement }) => {
  return (
    <>
      <Sentry.ErrorBoundary fallback={() => <ErrorFallback />} showDialog>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
              <CurrencyProvider>
                <OtherContextProvider>
                  <WalletProvider>{children}</WalletProvider>
                </OtherContextProvider>
              </CurrencyProvider>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </Sentry.ErrorBoundary>
    </>
  )
}
