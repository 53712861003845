import { Transaction, TransactionSigner } from 'algosdk'

import MagicLink from './wallets/magiclink'
import PeraConnectWallet from './wallets/peraconnect'
import { PermissionCallback, SignedTxn, Wallet } from './wallets/wallet'
import WC from './wallets/walletconnect'

export type { PermissionResult, PermissionCallback, Wallet, SignedTxn } from './wallets/wallet'

export const allowedWallets = {
  'wallet-connect': WC,
  'magic-link': MagicLink,
  'pera-connect': PeraConnectWallet,
}

type WalletNames = 'wallet-connect' | 'magic-link' | 'pera-connect'
type WalletPreference = WalletNames | null

const walletPreferenceKey = 'wallet-preference'
const acctListKey = 'acct-list'
const acctPreferenceKey = 'acct-preference'
const mnemonicKey = 'mnemonic'

const connectByWalletName = {
  'magic-link': async (sessionWallet: SessionWallet) => {
    await sessionWallet.wallet.connect({
      email: sessionWallet.email,
      apiKey: sessionWallet.apiKey,
      rpcURL: sessionWallet.rpcURL,
    })
    sessionWallet.setAccountList(sessionWallet.wallet.accounts)
    sessionWallet.wallet.defaultAccount = sessionWallet.accountIndex()
  },
  'wallet-connect': async (sessionWallet: SessionWallet) => {
    await sessionWallet.wallet.connect((acctList: string[]) => {
      sessionWallet.setAccountList(acctList)
      sessionWallet.wallet.defaultAccount = sessionWallet.accountIndex()
    })
  },
  'pera-connect': async (sessionWallet: SessionWallet) => {
    await sessionWallet.wallet.connect()
    sessionWallet.setAccountList(sessionWallet.wallet.accounts)
    sessionWallet.wallet.defaultAccount = sessionWallet.accountIndex()
  },
}

export class SessionWallet {
  wallet: Wallet
  walletName: WalletNames
  network: string
  apiKey: string
  permissionCallback?: PermissionCallback
  rpcURL: string
  email: string

  constructor(
    network: string,
    walletName: WalletNames,
    email: string,
    apiKey: string,
    magiclinkRpcURL: string,
    permissionCallback?: PermissionCallback
  ) {
    if (walletName) this.setWalletPreference(walletName)
    this.network = network
    const _walletName = this.walletPreference()
    if (!_walletName) throw Error('[SessionWallet]: walletName is not in walletPreference')
    this.walletName = _walletName
    if (permissionCallback) this.permissionCallback = permissionCallback
    if (!(this.walletName in allowedWallets))
      throw Error('[SessionWallet]: walletName is not in allowedWallets')

    this.apiKey = apiKey
    this.rpcURL = magiclinkRpcURL
    this.email = email
    this.wallet = new allowedWallets[this.walletName](network)
    this.wallet.permissionCallback = this.permissionCallback
    this.wallet.accounts = this.accountList()
    this.wallet.defaultAccount = this.accountIndex()
  }

  async connect(): Promise<void> {
    if (this.wallet === undefined) throw Error('[SessionWallet]: wallet is undefined')

    try {
      await connectByWalletName[this.walletName](this)
      this.wallet.defaultAccount = this.accountIndex()
    } catch (e) {
      // Fail
      await this.disconnect()
      throw e
    }
  }

  async connected(): Promise<boolean> {
    return this.wallet !== undefined && (await this.wallet.isConnected())
  }

  getSigner(): TransactionSigner {
    return (txnGroup: Transaction[], indexesToSign: number[]) => {
      return Promise.resolve(this.signTxn(txnGroup)).then((txns) => {
        return txns
          .map((tx) => {
            return tx.blob
          })
          .filter((_, index) => indexesToSign.includes(index))
      })
    }
  }

  setAccountList(accts: string[]) {
    sessionStorage.setItem(acctListKey, JSON.stringify(accts))
  }
  accountList(): string[] {
    const accts = sessionStorage.getItem(acctListKey)
    return accts === '' || accts === null ? [] : JSON.parse(accts)
  }

  setAccountIndex(idx: number) {
    this.wallet.defaultAccount = idx
    sessionStorage.setItem(acctPreferenceKey, idx.toString())
  }
  accountIndex(): number {
    const idx = sessionStorage.getItem(acctPreferenceKey)
    return idx === null || idx === '' ? 0 : parseInt(idx, 10)
  }

  setWalletPreference(walletName: WalletNames) {
    this.walletName = walletName
    sessionStorage.setItem(walletPreferenceKey, walletName)
  }
  walletPreference(): WalletPreference {
    const wp: any = sessionStorage.getItem(walletPreferenceKey)
    return wp
  }

  setMnemonic(m: string) {
    sessionStorage.setItem(mnemonicKey, m)
  }
  mnemonic(): string {
    const mn = sessionStorage.getItem(mnemonicKey)
    return mn === null ? '' : mn
  }

  async disconnect() {
    if (this.wallet !== undefined) this.wallet.disconnect()
    sessionStorage.setItem(walletPreferenceKey, '')
    sessionStorage.setItem(acctPreferenceKey, '')
    sessionStorage.setItem(acctListKey, '')
    sessionStorage.setItem(mnemonicKey, '')
  }

  async getDefaultAccount(): Promise<string> {
    if (!(await this.connected())) throw Error('Wallet not connected')
    return this.wallet.getDefaultAccount()
  }

  async signTxn(txns: Transaction[], forceAuth = false): Promise<SignedTxn[]> {
    const connected = await this.connected()
    if (!connected) await this.connect()
    return this.wallet.signTxn(txns, forceAuth)
  }
}
