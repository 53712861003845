import { useQuery } from 'react-query'

import { CarbonDocument } from '@/features/documents'
import { httpClient } from '@/lib/httpClient'

function fetchProject(id: string): Promise<CarbonDocument> {
  return httpClient.get(`/carbon-documents/${id}`)
}

export function useGetProject(id: string) {
  return useQuery(['project'], () => fetchProject(id))
}
