import { useQuery } from 'react-query'

import { algoIndexer } from '@/lib/algoIndexer'

export interface AssetInfo {
  asset: {
    'asset-tx-counter': number
    'created-at-round': number
    'created-at': number
    'creation-txid': string
    deleted: boolean
    index: number
    params: {
      'circulating-supply': number
      creator: string
      decimals: number
      'default-frozen': boolean
      manager: string
      name: string
      'name-b64': string
      reserve: string
      total: number
      'unit-name': string
      'unit-name-b64': string
    }
  }
  'current-round': number
}

async function fetchGeneralStats(): Promise<AssetInfo> {
  return await algoIndexer.get(
    `/assets/${process.env.REACT_APP_CLIMATECOIN_ASA_ID}?include-all=true`
  )
}

export function useGeneralStats() {
  return useQuery(['general-stats'], fetchGeneralStats, {
    staleTime: 60 * 60,
  })
}
