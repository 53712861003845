import { useQuery } from 'react-query'

import { httpClient } from '@/lib/httpClient'

export interface CurrencyInfo {
  _id: string
  usd_eur: number
  usd_jpy: number
  usd_gbp: number
  usd_btc: number
  createdAt: Date
  updatedAt: Date
  usd_usd: number
  usd_climatecoin: number
}

function fetchCurrenciesExchangeRate(): Promise<CurrencyInfo> {
  return httpClient.get(`/currency`)
}

export function useGetCurrenciesExchangeRate() {
  return useQuery(['currencies'], fetchCurrenciesExchangeRate, {
    staleTime: 60 * 60,
  })
}
