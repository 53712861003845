import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DlItem } from '@/componentes/DescriptionList'
import { Dialog } from '@/componentes/Dialog/Dialog'
import { Input } from '@/componentes/Form/Inputs'

import { useUpdateProfile } from '../../../features/users/api/useUpdateProfile'
import { ProfileValidationSchema } from '../../../features/users/validation/ProfileValidation'

type EmailDialogProps = {
  email: string | undefined
}

export const EmailDialog = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(true)
  const [validEmail, setValidEmail] = useState(true)
  const inputEmailFocus = useRef(null)

  const methods = useForm<ProfileValidationSchema>({
    mode: 'onBlur',
  })

  const updateProfile = useUpdateProfile()

  const baseInputProps = {
    register: methods.register,
    control: methods.control,
    errors: methods.formState.errors,
    wrapperClassName: 'col-span-2',
  }

  const handleSubmit = (data: EmailDialogProps) => {
    if (validateEmail(data)) {
      setValidEmail(true)
      updateProfile.mutate(data)
    } else {
      setValidEmail(false)
    }
  }

  const validateEmail = (data: EmailDialogProps) => {
    if (data.email) {
      const validateRegex =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      return validateRegex.test(data.email)
    }
  }

  return (
    <Dialog
      initialFocus={inputEmailFocus}
      size="xs"
      isOpen={isOpen}
      setIsOpen={() => {
        setIsOpen(!isOpen)
        localStorage.setItem('dialogDisplayed', 'true')
      }}
      acceptLabel={t('Wallet.action.dialog.send.acceptLabel')}
      cancelLabel={t('Wallet.action.dialog.cancel')}
      onCancel={() => {
        setIsOpen(!isOpen)
        localStorage.setItem('dialogDisplayed', 'true')
      }}
      title={t('misc.Overview.mail.title')}
      claim={t('misc.Overview.mail.claim')}
      onAccept={methods.handleSubmit(handleSubmit)}
      isLoading={updateProfile.isLoading}
    >
      <>
        <DlItem
          dt={t('misc.Overview.mail.label')}
          dd={
            <form
              className="space-y-4"
              ref={inputEmailFocus}
              onSubmit={methods.handleSubmit(handleSubmit)}
            >
              <Input<ProfileValidationSchema>
                name="email"
                type="text"
                placeholder={'Email'}
                {...baseInputProps}
              />
              {!validEmail && (
                <div className="text-red-500 text-sm">Please enter a valid email.</div>
              )}

              <Input<ProfileValidationSchema>
                label="Metamask address"
                name="metamaskAddress"
                type="text"
                placeholder={'Metamask address'}
                {...baseInputProps}
              />
            </form>
          }
          dtClassNames="pb-1"
          ddClassNames="pb-8"
        />
      </>
    </Dialog>
  )
}
