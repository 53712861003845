import { useTranslation } from 'react-i18next'

import { CarbonDocument } from '@/features/documents'

import { sdgList } from '../../api/sdgList'
import { getSdgFromProject } from '..'

type ProjectSdgProps = {
  project: CarbonDocument
}

const ProjectSdg = ({ project }: ProjectSdgProps) => {
  const { t } = useTranslation()
  const sdgProjectArray = getSdgFromProject(project, sdgList).flat(1)

  return (
    <div>
      {sdgProjectArray.map((sdg) => (
        <div key={sdg.id}>
          <div className="flex py-3">
            <img className="h-10 w-10 rounded-md" src={sdg.icon} alt={sdg.name} />
            <div className="pl-4">
              <p>{t(`sdgs.sdg${sdg.id}.name`)}</p>
              <p className="text-neutral-4">
                {`${t(`sdgs.sdg${sdg.id}.description`).substring(0, 120)}...`}
              </p>
            </div>
          </div>
          <hr />
        </div>
      ))}
    </div>
  )
}

export default ProjectSdg
