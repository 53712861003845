import { format } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/componentes/Elements/Button/Button'
import { Link } from '@/componentes/Elements/Link/Link'
import { Pill, PillProps } from '@/componentes/Elements/Pill/Pill'
import { Spinner } from '@/componentes/Elements/Spinner/Spinner'
import { Title } from '@/componentes/Elements/Title/Title'
import { Icon } from '@/componentes/Icon/Icon'
import { Head } from '@/componentes/Layout/Head'
import { MainLayout } from '@/componentes/Layout/MainLayout'
import { BalanceShowcase } from '@/features/misc'
import { useAuth } from '@/lib/auth'
import { useWalletContext } from '@/providers/Wallet.context'

import OverviewImage from '../../../assets/images/overview.jpg'
import { useGetChartData } from '../../wallet/api/useGetChartData'
import { useGetActivities } from '../api/useGetActivities'
import { EmailDialog } from '../components/EmailDialog'
import { WarningMessage } from '../components/WarningMessage'

const pillVariants: Record<string, PillProps['variant']> = {
  swap: 'swap',
  buy: 'featured',
  sell: 'popular',
  burn: 'popular',
  offset: 'comingSoon',
}

export const Overview = () => {
  const { t } = useTranslation()
  const [tabSelected, setTabSelected] = useState<string | null>(null)
  const activities = useGetActivities(tabSelected)
  const auth = useAuth()
  const { climatecoinBalance } = useWalletContext()
  const chartBalance = useGetChartData()

  const tabs = [
    {
      label: t('components.Overview.all'),
      value: null,
    },
    {
      label: t('components.Overview.mint'),
      value: 'mint',
    },
    {
      label: t('components.Overview.burn'),
      value: 'burn',
    },
  ]

  const renderActivity = () => {
    if (activities?.data) {
      return (
        <tbody className="text-sm">
          {activities.data.map((data) => {
            return (
              <tr key={data.id}>
                <td>
                  <Pill
                    style="solid"
                    className="text-xs"
                    variant={data.type ? pillVariants[data.type] : 'new'}
                  >
                    {data.type}
                  </Pill>
                </td>
                <td>{t('intlNumber', { val: data.supply }) ?? 0}</td>
                <td>
                  {data.nft ? (
                    <>
                      {data.nft.metadata.description}
                      <br />
                      <Link
                        href={`${process.env.REACT_APP_ALGORAND_EXPLORER_URL}asset?id=${data.nft.asa_id}`}
                        className="inline-flex items-center text-xs"
                      >
                        <>
                          {t('misc.Overview.view.asset')}

                          <img src="icons/algoexplorer.png" className="h-3 w-3 rounded-full" />
                        </>
                      </Link>{' '}
                    </>
                  ) : null}
                  {data.type === 'burn' ? (
                    <>
                      {t('misc.Overview.compensated.cc')}
                      <br />
                    </>
                  ) : null}
                  <Link
                    href={
                      data.is_group
                        ? `${
                            process.env.REACT_APP_ALGORAND_EXPLORER_URL
                          }tx-group/${encodeURIComponent(data.group_id as string)}`
                        : `${process.env.REACT_APP_ALGORAND_EXPLORER_URL}transactions?id=${data?.nft?.asa_txn_id}`
                    }
                    className="inline-flex items-center text-xs"
                  >
                    <>
                      {t('misc.Overview.view.txn')}

                      <img
                        role="figure"
                        src="icons/algoexplorer.png"
                        className="h-3 w-3 rounded-full"
                      />
                    </>
                  </Link>
                </td>
                <td className="text-right">{format(new Date(data.date), 'dd/MM/yyyy')}</td>
              </tr>
            )
          })}
        </tbody>
      )
    }
    if (activities.error instanceof Error) {
      return (
        <table>
          <tbody>
            <tr>
              <td colSpan={4}>
                {('An error has occurred: ' + activities.error.message) as string}
              </td>
            </tr>
          </tbody>
        </table>
      )
    }
    return (
      <table>
        <tbody>
          <tr>
            <td colSpan={4}>
              <Spinner />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <MainLayout title={t('misc.Overview.title')}>
      <Head title={t('misc.Overview.title')} />

      {auth?.user?.email &&
        auth.user?.confirmed === false &&
        !localStorage.getItem('warningMessage') &&
        auth?.user?.email !== undefined && (
          <WarningMessage message={t('misc.Overview.mail.verification.message')} />
        )}

      {!auth?.user?.email &&
        !localStorage.getItem('warningMessage') &&
        localStorage.getItem('dialogDisplayed') && (
          <WarningMessage
            message={t('misc.Overview.mail.noEmail.message')}
            linkMessage={t('misc.Overview.mail.noEmail.link')}
          />
        )}

      {!auth?.user?.email && !localStorage.getItem('dialogDisplayed') && <EmailDialog />}

      <div id="top-row" className="flex flex-col p-8">
        <Title size={4} as={1}>
          {t('misc.Overview.title')}
        </Title>
        <BalanceShowcase climatecoinBalance={climatecoinBalance} chartBalance={chartBalance} />
      </div>

      <div id="bottom-row" className="grid gap-7 p-8 md:grid-cols-3">
        <div
          id="view-more-panel"
          className="flex h-[27.25rem] flex-col justify-end rounded-xl bg-cover bg-center px-8 pb-6 text-neutral-9 "
          style={{ backgroundImage: `url(${OverviewImage})` }}
        >
          <Title size={4} as={3}>
            {t('components.Overview.viewMore.title')}
          </Title>
          <p className="text-sm text-neutral-6">{t('components.Overview.viewMore.subtitle')}</p>
          <div className="mt-8 ">
            <Link
              size="sm"
              iconRight={<Icon id="arrow-right" className="ml-1 h-4 w-4" />}
              variant="light"
              to="/"
              as="button"
            >
              {t('components.Overview.viewMore.link')}
            </Link>
          </div>
        </div>
        <div id="activity-panel" className="flex flex-col space-y-4 md:col-span-2">
          <div id="activity-panel-tabs" className="flex gap-4 ">
            {tabs.map((tab) => {
              return (
                <Button
                  onClick={() => setTabSelected(tab.value)}
                  size="xs"
                  key={tab.label}
                  variant="light"
                >
                  {tab.label}
                </Button>
              )
            })}
          </div>
          <hr />
          <Title size={4} as={1}>
            {t('components.Overview.activity')}
          </Title>
          <div>
            <table className="w-full border-separate [border-spacing:1rem] md:col-span-2 ">
              <thead>
                <tr className="mb-5 border-b text-left text-xs">
                  <th>{t('components.Overview.type')}</th>
                  <th>{t('components.Overview.total')}</th>
                  <th>{t('components.Overview.operationId')}</th>
                  <th className="text-right ">{t('components.Overview.date')}</th>
                </tr>
              </thead>
              {renderActivity()}
            </table>
            <div className="px-4">
              <Link to={'/'}>{t('misc.Overview.view.allActivity')}</Link>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
