import { useTranslation } from 'react-i18next'

import { Link } from '@/componentes/Elements/Link/Link'
import { Title } from '@/componentes/Elements/Title/Title'
import { Icon } from '@/componentes/Icon/Icon'
import { CLIMATECOIN_ASA_ID, NETWORKNAME, VAULT_CONTRACT_ID } from '@/config'

import { Head } from './Head'
import { Navbar } from './Navbar/Navbar'

interface MainLayoutProps {
  title?: string
  children: any
}

export const MainLayout = ({ title, children }: MainLayoutProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Head title={title} />
      <div className="flex min-h-screen flex-col pb-12">
        <Navbar />
        <div className="mx-auto w-full max-w-screen-xl flex-grow">{children}</div>
        <footer className="mx-auto mt-12 w-full max-w-screen-xl">
          <div className="flex flex-row justify-center space-x-20">
            <div className="space-y-2 mr-20">
              <div className="flex justify-center">
                <Title size={6} as={3}>
                  {t('mainlayout.footer.section.aboutUs')}
                </Title>
              </div>
              <div className="flex justify-center ">
                <Link href={'https://climatecoin.com'} className="flex items-center">
                  <div className="flex space-x-1">
                    <div>
                      <img src="/climatecoin-icon.png" className="h-6 w-5 " />
                    </div>
                    <div>climatecoin.com</div>
                  </div>
                </Link>
              </div>
              <div className="flex flex-row justify-center space-x-5">
                <div>
                  <Link href={'https://twitter.com/Climatecoin'} className="flex items-center">
                    <img src="/twitter3.png" className="h-7 w-7" />
                  </Link>
                </div>
                <div>
                  <Link
                    href={'https://www.linkedin.com/company/climatecoin/'}
                    className="flex items-center"
                  >
                    <img src="/linkedin3.png" className="h-7 w-7" />
                  </Link>
                </div>
                <div>
                  <Link
                    href={'https://discord.com/invite/GYxF85mtz8'}
                    className="flex items-center"
                  >
                    <img src="/discord3.png" className="h-7 w-7" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <Title size={6} as={3}>
                {t('mainlayout.footer.section.links')}
              </Title>
              <Link
                href={`${process.env.REACT_APP_ALGORAND_EXPLORER_URL}application/${Number(
                  VAULT_CONTRACT_ID
                )}`}
                className="inline-flex items-center"
              >
                <div className="flex space-x-1">
                  <div>
                    <Icon id={`network-${NETWORKNAME}`} className="h-6 w-5 rounded-full" />
                  </div>
                  <div>{NETWORKNAME}</div>
                </div>
              </Link>
              <Link
                href={`${process.env.REACT_APP_ALGORAND_EXPLORER_URL}asset?id=${CLIMATECOIN_ASA_ID}`}
                className="flex items-center"
              >
                <div className="flex space-x-1">
                  <div>
                    <img src="/climatecoin-icon.png" className="h-6 w-5 rounded-full" />
                  </div>
                  <div>Climatecoin</div>
                </div>
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}
