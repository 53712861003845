import clsx from 'clsx'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useIsMutating } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card } from '@/componentes/Card/Card'
import { Dl, DlItem } from '@/componentes/DescriptionList'
import { Button } from '@/componentes/Elements/Button/Button'
import { Link } from '@/componentes/Elements/Link/Link'
import { Spinner } from '@/componentes/Elements/Spinner/Spinner'
import { Title } from '@/componentes/Elements/Title/Title'
import { Input } from '@/componentes/Form/Inputs'
import { Stepper, useStepper } from '@/componentes/Stepper/Stepper'
import { useCurrencyContext } from '@/providers/Currency.context'
import { useOtherContext } from '@/providers/Other.context'
import { useWalletContext } from '@/providers/Wallet.context'

import { useBurnClimatecoins } from '../api/burnClimatecoins'
import { useCalculateCompensation } from '../api/calculateCompensation'
import { CompensationCalculation } from '../types'
import { CalculateCompensationSchema } from '../validation/CompensateValidation'

export enum CompensateSteps {
  INITIAL = 0,
  CONFIRMATION = 1,
  SUCCESS = 2,
}
export const CompensateForm = ({ defaultAddress }: { defaultAddress: string }) => {
  const search = useLocation().search
  const { t } = useTranslation()
  const { account, climatecoinBalance } = useWalletContext()
  const calculateCompensation = useCalculateCompensation()
  const burnClimatecoins = useBurnClimatecoins()
  const { formatter, climatecoinValue } = useCurrencyContext()
  const isMutating = useIsMutating()
  let isMounting = false
  const [amount, setAmount] = useState(0)
  const [oracleResponse, setOracleResponse] = useState<null | CompensationCalculation>(null)
  const [userCcoins, setUserCcoins] = useState<number>(0)
  const { defaultFifoProject, setFifoProject } = useOtherContext()

  const methods = useForm<CalculateCompensationSchema>({
    mode: 'onBlur',
    defaultValues: {
      wallet: { value: defaultAddress as any, label: defaultAddress as any },
    },
  })

  const baseInputProps = {
    register: methods.register,
    control: methods.control,
    errors: methods.formState.errors,
    wrapperClassName: 'col-span-2',
  }

  const redirect_amount = new URLSearchParams(search).get('amount')
  const selected_project = new URLSearchParams(search).get('project')

  useEffect(() => {
    const onMount = () => {
      if (!selected_project) return
      methods.setValue('amount', Number(redirect_amount))
      handleSubmit({ amount: redirect_amount, project_selected: selected_project })
    }
    if (isMounting) return
    isMounting = true
    onMount()
  }, [])

  const handleSubmit = async (data: any) => {
    setAmount(data.amount)

    // check if the user change the project and choose again the default fifo project
    if (data.project_selected !== undefined && defaultFifoProject === data.project_selected)
      data.project_selected = undefined

    Promise.all([
      setCurrStep(CompensateSteps.CONFIRMATION),
      calculateCompensation
        .mutateAsync({ project_selected: data.project_selected, amount: data.amount })
        .then((oracleTxn) => {
          setOracleResponse(oracleTxn)
          if (data.project_selected == undefined)
            setFifoProject(oracleTxn.pickedNfts[0].carbon_document.id)
        }),
    ]).catch(() => setCurrStep(CompensateSteps.INITIAL))
    const res = await climatecoinBalance()
    setUserCcoins(res)
  }
  const navigate = useNavigate()

  const handleConfirmation = () => {
    // setCurrStep(CompensateSteps.INITIAL)
    const adrr_aux = account?.address
    const address_aux: string = adrr_aux!
    const proj_aux = selected_project!

    if (!oracleResponse) return Promise.reject('No oracle response')
    burnClimatecoins.mutate(
      {
        ...oracleResponse,
      },
      {
        onSuccess: (res) => {
          setOracleResponse(null)
          navigate(`/coins/compensate/${res.id}?amount=${amount}`)
          ReactGA.event({
            category: 'compensation',
            action: address_aux,
            label: proj_aux,
            value: Number(amount),
          })
        },
      }
    )
  }

  const { currStep, setCurrStep } = useStepper(CompensateSteps)

  const amountPills = [10, 25, 50, climatecoinBalance()]

  return (
    <div className="grid gap-8 md:grid-cols-3">
      <div>
        <Stepper
          stepsEnum={CompensateSteps}
          setCurrStep={setCurrStep}
          currStep={currStep}
          translationRoot="compensations.Compensate.stepper"
          errors={{}}
          disableFutureSteps={true}
        />
      </div>
      <div className="flex flex-col space-y-8 md:col-span-2">
        {currStep === CompensateSteps.INITIAL && (
          <Card>
            <Title size={5} as={2}>
              {t('compensations.Compensate.card.title')}
            </Title>
            <form className="mt-6 space-y-8" onSubmit={methods.handleSubmit(handleSubmit)}>
              <div className="flex flex-col">
                <span className="text-sm text-neutral-4">
                  {t('compensations.Compensate.wallet.label')}
                </span>
                <div className="flex w-full justify-between rounded bg-neutral-7 p-2">
                  <span>{defaultAddress}</span>
                  <span className="ml-2 text-neutral-4">({climatecoinBalance()} cc)</span>
                </div>
              </div>
              <div className="space-y-2">
                <div className="relative flex items-center justify-center">
                  <div
                    className={clsx('absolute z-10 -mt-4 text-2xl font-bold')}
                    style={{
                      marginLeft: `-${
                        (typeof methods.watch('amount') === 'undefined'
                          ? 4
                          : methods.watch('amount').toString().length > 7
                            ? 7
                            : methods.watch('amount').toString().length < 4
                              ? 4
                              : methods.watch('amount').toString().length) * 4.5
                      }rem`,
                    }}
                  >
                    CC
                  </div>
                  <Input
                    {...baseInputProps}
                    wrapperClassName="col-span-1"
                    label={t('compensations.Compensate.amount.label')}
                    name="amount"
                    type="number"
                    required
                    min={1}
                    defaultValue={1}
                    max={climatecoinBalance()}
                    inputClassName="!min-w-[200px] !border-0 !text-[5rem] !w-full !text-center !showArrows"
                    placeholder={'1'}
                    labelClassName="text-center"
                  />
                </div>
                <div className="text-center text-xl">
                  {formatter(climatecoinValue(methods.watch('amount')))}
                </div>

                <div className="flex justify-center gap-4">
                  {amountPills.map((amt, i) => (
                    <Button
                      type="button"
                      key={amt}
                      variant={'light'}
                      size="xs"
                      onClick={() => {
                        methods.setValue(
                          'amount',
                          amt === climatecoinBalance()
                            ? climatecoinBalance()
                            : Math.round((amt * climatecoinBalance()) / 100)
                        )
                      }}
                    >
                      {i === amountPills.length - 1 ? `Max ( ${amt} )` : `${amt} %`}
                    </Button>
                  ))}
                </div>
              </div>

              <div className="flex items-center justify-center space-x-4">
                <Button type="submit" size="md" disabled={isMutating > 0}>
                  {t('compensations.Compensate.button')}
                </Button>
              </div>
            </form>
          </Card>
        )}
        {currStep === CompensateSteps.CONFIRMATION && (
          <Card>
            <div className="space-y-8">
              <Title size={5} as={2}>
                {t('compensations.Compensate.steps.accept.title')}
              </Title>
              <p className="text-sm text-neutral-4">
                {t('compensations.Compensate.steps.accept.description', {
                  amount: t('intlNumber', { val: methods.getValues('amount') }),
                  token: methods.getValues('amount') === 1 ? 'token' : 'tokens',
                })}
                <span className="font-bold text-black">
                  {t('compensations.Compensate.steps.accept.fee')}
                </span>
                {t('compensations.Compensate.steps.accept.thankyou')}
                <Link to="/">
                  <span className="text-neutral-4">
                    {/* {t('compensations.Compensate.steps.accept.more')} */}
                  </span>
                </Link>
              </p>
              <Dl>
                <DlItem
                  dt={t('compensations.Compensate.card.total.coins')}
                  dd={`${t('intlNumber', { val: methods.getValues('amount') })} cc`}
                  ddClassNames="text-primary-brightGreen"
                />
                <DlItem
                  dt={t('compensations.Compensate.card.total.value')}
                  dd={formatter(climatecoinValue(methods.getValues('amount')))}
                />
                <hr className="col-span-2" />
                <DlItem
                  dt={t('compensations.Compensate.card.adress')}
                  dd={
                    account?.address
                      ? `${account.address?.slice(0, 15)}...${account.address?.slice(-15)}`
                      : ''
                  }
                  fullWidth
                />
              </Dl>

              {oracleResponse?.pickedNfts.map((nft) => (
                <div key={nft.asa_id} className="col-span-2">
                  <Card shadow={false}>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex flex-row">
                        <div className="relative mr-5">
                          <img
                            className="h-auto min-h-full w-32 min-w-full rounded object-cover"
                            src={nft.carbon_document.thumbnail.url}
                            alt={nft.carbon_document.title}
                          />
                          <div className="absolute bottom-0 left-0 mx-2.5 my-2 rounded-full bg-white px-2 py-1 text-xs opacity-70">
                            <span className="text-neutral-1">
                              {nft.carbon_document.credit_start.substr(0, 4)}
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col justify-between ">
                          <p className="text-lg font-medium leading-7 text-neutral-1">
                            {nft.carbon_document.title}
                          </p>
                          <div>
                            <p className="text-sm text-neutral-4">
                              <span>{nft.carbon_document.project_type.name} </span>-
                              <span> {nft.carbon_document.country.name}</span>
                            </p>
                            <p className="text-sm font-medium text-neutral-1">
                              <span>
                                {/* {nft.carbon_document.credits}{' '} */}
                                {nft.supply_remaining} {t('compensations.Compensate.card.credits')}
                              </span>{' '}
                              -{' '}
                              <span className="text-primary-brightGreen">
                                {t('compensations.Compensate.card.from')}{' '}
                                {nft.carbon_document.credit_start.substr(0, 4)}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <Link
                        as="button"
                        to={`/projects?amount=${amount}`}
                        variant="green"
                        className={`hover:none text-white`}
                        navLink
                      >
                        {t('compensations.Compensate.card.change')}
                      </Link>
                    </div>
                  </Card>
                </div>
              ))}

              {oracleResponse && oracleResponse.amount + oracleResponse.fee > userCcoins ? (
                <div className="flex space-x-2">
                  {/* <div className="flex items-center justify-end">
                    {isMutating > 0 && <Spinner size="md" />}
                  </div> */}
                  {/* <div className="flex"> */}
                  <div className="flex w-3/4 bg-yellow-300 rounded-md px-2 py-1 ">
                    <span>
                      Your balance is <b>{userCcoins} ccoins</b>, so you can not compensate{' '}
                      <b>{oracleResponse.amount} ccoins</b> as we charge you{' '}
                      <b>{oracleResponse.fee} ccoins</b> as a fee for choosing a different
                      compensation project, please change to the default project or enter a new
                      amount of compensation from{' '}
                      <a
                        href="#"
                        style={{ color: 'black', textDecoration: 'underline' }}
                        onClick={() => setCurrStep(CompensateSteps.INITIAL)}
                      >
                        here
                      </a>
                    </span>
                  </div>
                  <div className="flex w-1/4 items-center">
                    <Button disabled={true} onClick={handleConfirmation} className="w-48 h-12">
                      {t('documents.Upload.stepper.continue')}
                    </Button>
                  </div>
                  {/* </div> */}
                </div>
              ) : (
                <div className="grid grid-cols-3 gap-4">
                  <div></div>
                  <div className="flex items-center justify-end">
                    {isMutating > 0 && <Spinner size="md" />}
                  </div>
                  <Button disabled={isMutating > 0} onClick={handleConfirmation}>
                    {t('documents.Upload.stepper.continue')}
                  </Button>
                </div>
              )}
            </div>
          </Card>
        )}
      </div>
    </div>
  )
}
