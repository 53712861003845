import { CarbonDocument } from '@/features/documents'

export type SdgType = {
  id: number
  code: number
  name: string
  description: string
  icon: string
}

export const formatDate = (date: Date | string) => {
  const d = new Date(date)
  const newDay = d.getDate()
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const month = months[d.getMonth()].substring(3, 0)
  const year = d.getFullYear()

  return `${newDay} ${month}, ${year}`
}

export const getSdgFromProject = (project: CarbonDocument, sdgList: SdgType[]) => {
  return project.sdgs.map((sdg) => {
    const sgdProject = sdgList.filter((sdgFromList) => {
      return sdgFromList.name.toLocaleLowerCase() === sdg.name.toLocaleLowerCase()
    })
    return sgdProject
  })
}
