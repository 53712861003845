import algosdk from 'algosdk'
import { Buffer } from 'buffer'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { SignedTxn } from '@/algorand-session-wallet'
import { httpClient } from '@/lib/httpClient'
import { queryClient } from '@/lib/react-query'
import { sessionWallet } from '@/lib/sessionWallet'

import { Compensation, CompensationCalculation, compensationKeys } from '../types'

async function handleBurnClimatecoins({
  amount,
  encodedTransferTxn,
  encodedFundsTxn,
  encodedBurnTxn,
  signature,
  pickedNfts,
}: CompensationCalculation): Promise<Compensation> {
  // skip this in testing
  if (process.env.NODE_ENV === 'test') {
    return httpClient.post(`/compensations`, {
      signedTxn: [],
      amount,
      pickedNfts,
    })
  }
  if (!sessionWallet) throw Error('[handleBurnClimatecoins] !sessionWallet')
  // convert the txns to Transaction objects
  const transferTxn = algosdk.decodeUnsignedTransaction(
    Buffer.from(Object.values(encodedTransferTxn))
  )
  const fundsTxn = algosdk.decodeUnsignedTransaction(Buffer.from(Object.values(encodedFundsTxn)))
  const burnTxn = algosdk.decodeUnsignedTransaction(Buffer.from(Object.values(encodedBurnTxn)))

  const signedTxns: SignedTxn[] | undefined = await sessionWallet.signTxn([
    transferTxn,
    fundsTxn,
    burnTxn,
  ])
  if (!signedTxns) return Promise.reject('Transaction not signed')

  const signedTxnBlobs = signedTxns.map((stxn) => stxn.blob)

  return httpClient.post(`/compensations`, {
    signedTxn: signedTxnBlobs,
    signature,
    amount,
    pickedNfts,
  })
}

export function useBurnClimatecoins() {
  return useMutation((burnParams: CompensationCalculation) => handleBurnClimatecoins(burnParams), {
    onSuccess: () => {
      queryClient.invalidateQueries(['account'])
      queryClient.invalidateQueries(compensationKeys.lists())
      toast.success('Climatecoins compensated succesfully')
    },
    onError: () => {
      toast.error('Error burning compensations')
    },
  })
}
