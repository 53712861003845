import algosdk, { waitForConfirmation } from 'algosdk'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { accountKeys, AssetTransfer } from '@/features/wallet'
import { getClient } from '@/lib/algosdk'
import { sessionWallet } from '@/lib/sessionWallet'
import { useWalletContext } from '@/providers/Wallet.context'

export function useTransferAsset() {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { account } = useWalletContext()

  const transferAsset = async (asaId: number, amount: number, receiverAddress: string) => {
    if (!account) throw Error('[transferAsset] !account')
    if (!account.address) throw Error('[transferAsset] !account.address')
    if (!sessionWallet) throw Error('[transferAsset] !sessionWallet')
    if (!algosdk.isValidAddress(receiverAddress)) return Promise.reject(t('Transfer.adress.error'))
    const suggestedParams = await getClient().getTransactionParams().do()

    const transactionOptions = {
      from: account.address,
      to: receiverAddress,
      assetIndex: asaId,
      amount: amount,
      suggestedParams,
    }

    const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject(transactionOptions)

    const signedTxns = await sessionWallet.signTxn([txn])
    if (!signedTxns) return Promise.reject(t('Transfer.txn.sign.error'))
    const signedTxn = signedTxns[0]

    const { txId } = await getClient().sendRawTransaction(signedTxn.blob).do()
    await waitForConfirmation(getClient(), txId, 4)
  }

  return useMutation(
    (assetTransfer: AssetTransfer) =>
      transferAsset(assetTransfer.asset['asset-id'], assetTransfer.amount, assetTransfer.receiver),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['account'])
        queryClient.invalidateQueries(accountKeys.all)
        toast.success(t('Transfer.asset.send.success'))
      },
      onError: () => {
        toast.error(t('Transfer.asset.send.errorOptin.title'))
      },
    }
  )
}
