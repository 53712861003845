import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { httpClient } from '@/lib/httpClient'

import { CertificateClaimTxns } from '../types'

async function fetchCertClaimTxns(compensationId: string): Promise<CertificateClaimTxns> {
  return httpClient.get(`/compensations/${compensationId}/claim/certificate`)
}

export function usePrepareClaimCertificate() {
  return useMutation(
    (compensationId: string) => {
      return fetchCertClaimTxns(compensationId)
    },
    {
      onError: () => {
        toast.error('Error claiming the certificate')
      },
    }
  )
}
