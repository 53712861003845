import { useQuery } from 'react-query'

import { CarbonDocument } from '@/features/documents'
import { httpClient } from '@/lib/httpClient'

export type FetchProjectsType = {
  status: string
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  isIdle: boolean
  data: CarbonDocument[]
  dataUpdatedAt: number
  error: null
  errorUpdatedAt: number
  failureCount: number
  errorUpdateCount: number
  isFetched: boolean
  isFetchedAfterMount: boolean
  isFetching: boolean
  isRefetching: boolean
  isLoadingError: boolean
  isPlaceholderData: boolean
  isPreviousData: boolean
  isRefetchError: boolean
  isStale: boolean
}

function fetchProjects(): Promise<CarbonDocument[]> {
  return httpClient.get(`/carbon-documents/`)
}

export function useGetProjects() {
  return useQuery(['projects'], fetchProjects)
}
