import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import IconQuestionMark from '@/componentes/Icon/IconQuestionMark'
import { CarbonDocument } from '@/features/documents'
import { useWalletContext } from '@/providers/Wallet.context'

import InputCompensation from './InputCompensation'

type ProjectCardProps = {
  project: CarbonDocument
  amountFromUrl: number
}

const ProjectCard = ({ project, amountFromUrl }: ProjectCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { climatecoinBalance } = useWalletContext()
  const [amount, setAmount] = useState(amountFromUrl || 0)
  const [error, setError] = useState('')

  const handleCompensation = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (amount <= 0) {
      setError('The amount to compensate must be greater than 0')
    } else {
      if (amount > climatecoinBalance()) {
        setError(`${t('projectDetail.projectCard.errorMsgNoFunds')} ${climatecoinBalance()} cc`)
      } else if (amount > Number(project.nft.supply_remaining)) {
        setError(
          `${t('projectDetail.projectCard.errorMsgNoSupply')} ${project.nft.supply_remaining} cc`
        )
      } else {
        setAmount(amountFromUrl ? amountFromUrl : Number((e.target as HTMLInputElement).value))
        navigate(`/coins/compensate?amount=${amount}&project=${project.id}`)
      }
    }
  }

  return (
    <div className="right-4 col-span-1">
      <div className="absolute -top-[105px] flex h-[304px] w-[312px] flex-col justify-around rounded-lg bg-white p-5 shadow-[0px_0px_25px_22px_rgba(0,0,0,0.1)]">
        <div className="flex justify-between">
          <p className="text-neutral-4">{t('projectDetail.projectCard.supplyRemaining')}</p>
          <p>{project.nft.supply_remaining} cc</p>
        </div>
        <hr />
        <div className="flex justify-between">
          <div className="flex items-center">
            <p className="text-neutral-4">{t('projectDetail.projectCard.amount')}</p>
            <IconQuestionMark />
          </div>
          {project.nft.supply_remaining && (
            <InputCompensation inputValue={amount} setInputValue={setAmount} />
          )}
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <button
          className="rounded-full bg-primary px-4 py-4 text-md font-bold text-neutral-8"
          onClick={(e) => handleCompensation(e)}
        >
          {t('projectDetail.projectCard.buttonCompensate')}
        </button>
      </div>
    </div>
  )
}

export default ProjectCard
