import { SessionWallet } from '@/algorand-session-wallet'

export let sessionWallet: null | SessionWallet

export function setSessionWallet(_sessionWallet: SessionWallet) {
  if (!sessionWallet) sessionWallet = _sessionWallet
}

export function removeSessionWallet() {
  sessionWallet = null
}
