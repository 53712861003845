type InputCompensationProps = {
  inputValue: number
  setInputValue: (number: number) => void
}

const InputCompensation = ({ inputValue, setInputValue }: InputCompensationProps) => {
  return (
    <div className="flex h-9 self-end">
      <div className="flex items-center rounded-lg border border-neutral-200 text-center">
        <input
          type="number"
          min="0"
          defaultValue={inputValue ?? 0}
          onChange={(e) => setInputValue(Number(e.target.value))}
          className="text-base w-[120px] border-transparent pl-4 text-black focus:border-transparent focus:outline-none focus:ring-0"
        />
      </div>
    </div>
  )
}

export default InputCompensation
