import { useQuery } from 'react-query'

import { httpClient } from '@/lib/httpClient'

async function fetchProjects(): Promise<number> {
  return await httpClient.get(`/carbon-documents/count`)
}

export function useDocumentsCount() {
  return useQuery(['documents-count'], fetchProjects, {
    staleTime: 60 * 60,
  })
}
