/* eslint-disable no-restricted-imports */
import { Navigate } from 'react-router-dom'

import { AuthRouter } from '@/features/auth'
import { Projects } from '@/features/projects/Projects'

import { GeneralStats } from '../features/generalStats/GeneralStats'

export const publicRoutes = [
  {
    path: '/general-stats',
    element: <GeneralStats />,
  },
  {
    path: '/projects',
    element: <Projects />,
  },
  {
    path: '/auth/*',
    element: <AuthRouter />,
  },
  { path: '*', element: <Navigate to="." /> },
  { path: '/', element: <Navigate to="/auth/login" /> },
]
