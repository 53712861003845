import { useMutation } from 'react-query'
  import { toast } from 'react-toastify'

import { httpClient } from '@/lib/httpClient'

import { CompensationCalculation } from '../types'

function fetchCalculateCompensation({
  amount,
  project_selected,
}: {
  amount: number;
  project_selected: string | undefined;
}): Promise<CompensationCalculation> {
  const params = new URLSearchParams({
    amount: amount.toString(),
  })
  if (project_selected) params.append('project_selected', project_selected)
  return httpClient.get(`/compensations/calculate?${params.toString()}`)
}

export function useCalculateCompensation() {
  
  return useMutation(
    ({ amount, project_selected }: { amount: number; project_selected: string | undefined }) => {
      return fetchCalculateCompensation({ amount, project_selected })
    },
    {
      onError: () => {
        toast.error('Error calculating compensation')
      },
    }
  )
}
