import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { allowedWallets } from '@/algorand-session-wallet'
import { ReactComponent as LockIcon } from '@/assets/icons/bx-lock-alt.svg'
import LogoMagic from '@/assets/icons/bx-magic-link.png'
import Logolight from '@/assets/logo-light.png'
import { Button } from '@/componentes/Elements/Button/Button'
import { Spinner } from '@/componentes/Elements/Spinner/Spinner'
import { Title } from '@/componentes/Elements/Title/Title'
import { Form } from '@/componentes/Form/Form'
import { Input } from '@/componentes/Form/Inputs'
import { Icon } from '@/componentes/Icon/Icon'
import { LoginLayout } from '@/componentes/Layout/LoginLayout'
import { WalletIssuer } from '@/features/auth'
import { useAuth } from '@/lib/auth'
import { sessionWallet } from '@/lib/sessionWallet'

export const Login = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isMagicLogin, setMagicLogin] = useState<boolean>(false)
  const [theIssuer, settheIssuer] = useState<WalletIssuer | null>(null)
  const [showTerms, setShowTerms] = useState(false)
  const [termsChecked, setTermsChecked] = useState(false)
  const [approveTerms, setApproveTerms] = useState(false)

  const handleCheckboxChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) }
  }) => {
    // setShowTerms(event.target.checked);
    setTermsChecked(event.target.checked)
    setApproveTerms(false)
  }

  const handleButtonClick = () => {
    setShowTerms(true)
  }

  const handleCloseModal = () => {
    setShowTerms(false)
  }

  const handleLogin = async (issuer: WalletIssuer, email = '') => {
    setIsLoading(true)
    settheIssuer(null)
    try {
      await auth.login({ email, issuer })
    } catch (e) {
      console.log('[handleLogin] error', e)
      toast.error('Error loging in')
      try {
        await sessionWallet?.disconnect()
      } catch (e) {
        //
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleMagicLogin = async (data: { email: string }) => {
    await handleLogin(WalletIssuer.MAGICLINK, data.email)
  }
  const handleExternalLogin = async (issuer: WalletIssuer) => {
    if (!termsChecked) setApproveTerms(true)
    else settheIssuer(issuer)
    // await handleLogin(issuer);
  }

  if (isMagicLogin === false) {
    if (theIssuer !== null)
      return (
        <LoginLayout title={t('auth.Login.pageTitle')}>
          <div
            className="col-span-2 col-start-1 mx-auto max-w-screen-sm w-full space-y-2 text-center"
            style={{
              backgroundColor: 'LightGray',
              borderRadius: 15,
              padding: 15,
              fontSize: 18,
              marginLeft: 70,
            }}
          >
            <img className="h-9" src={`${Logolight}`} />
            <br />
            {/* <p><b>In this demo we will send 10 climatecoins to your wallet.</b></p> */}
            <p>When you login you will receive 10 testnet Climatecoins in your wallet.</p>
            <p>You can use those testnet tokens to beta test the compensation feature</p>
            <p>without real carbon credits attached.</p>
            <br />
            <div className="flex col-start-2 space-x-4 justify-center">
              <Button
                type="button"
                onClick={() => settheIssuer(null)}
                disabled={isLoading}
                size="sm"
                variant="light"
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={() => handleLogin(theIssuer)}
                disabled={isLoading}
                size="sm"
                variant="light"
              >
                Connect
              </Button>
            </div>
          </div>
        </LoginLayout>
      )
    else
      return (
        <LoginLayout title={t('auth.Login.pageTitle')}>
          <div className="col-span-1 col-start-2 mx-auto w-full max-w-screen-sm space-y-8 text-left">
            {/* {true && <div><p>pepe</p></div>} */}
            <Title size={4} as={1} className="text-center">
              {t('auth.Login.pageTitle')}
            </Title>
            <div className="text-center">
              <p className="mb-4 text-sm text-neutral-4">{t('auth.Login.safetyWarning')}</p>
              <div className="flex items-center justify-center text-sm font-medium">
                <p className="flex items-center rounded-full bg-neutral-7 p-2 px-4">
                  <LockIcon />
                  <span className="text-primary-green">https://</span>beta.climatecoin.io
                </p>
              </div>
            </div>
            {showTerms && (
              <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>

                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>

                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <h2 className="text-lg font-medium leading-6 text-gray-900">
                        Terms and Conditions
                      </h2>
                      <div className="max-h-60 overflow-y-scroll mt-2">
                        <p className="mt-1 text-sm text-gray-500">
                          This BETA TESTER Agreement (“Agreement”) is entered into between Climate
                          Digital Investments, S.L., “Climate Coin ” and you as the customer,
                          “Customer”.
                        </p>
                        <p className="mt-1 text-sm text-black-500">
                          <b>1. License Grant. </b>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          Subject to the terms and conditions hereof, Climate Coin grants the
                          Customer, solely for the purpose of beta testing and not for general
                          commercial or other use, during a limited period set out below, a limited
                          non-exclusive, non-transferable, revocable license to use the Climate Coin
                          software product, ”Climate Coin Beta”. Customer shall not use the Climate
                          Coin Beta for the processing of any live or production data.
                        </p>
                        <p className="mt-1 text-sm text-black-500">
                          <b>2. Termination. </b>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          Either party may terminate this Agreement upon written notice to the other
                          party at any time. Within three (3) days after termination, Customer shall
                          return or, upon request by Climate Coin , destroy any and all information
                          and software and data Customer has received during the Beta test. Climate
                          Coin may at its sole discretion erase and destroy all accounts and data
                          related to Customer’s beta test use upon termination of the Agreement.
                        </p>
                        <p className="mt-1 text-sm text-black-500">
                          <b>3. Ownership </b>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          Climate Coin shall hold title to any and all intellectual property rights
                          and technical solutions to Climate Coin Beta or, in the alternative, shall
                          possess a sole right to use the same. Such intellectual property rights
                          and technical solutions may only be used by the Customer in the manner
                          stated in this Agreement. Under no circumstances shall the Customer or a
                          third party acquire any intellectual property rights to Climate Coin Beta
                          or to the software or technical solutions used in Climate Coin Beta, or to
                          any trade mark or any other business mark belonging to or used by Climate
                          Coin . Access to Climate Coin Beta is licensed, not sold.
                        </p>
                        <p className="mt-1 text-sm text-black-500">
                          <b>4. Confidentiality </b>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          Customer acknowledges that Climate Coin Beta contains critical
                          confidential information and business secrets of Climate Coin and its
                          licensors. Customer may not copy, distribute, sell, sublicense or
                          otherwise transfer or make available Climate Coin Beta or any portion
                          thereof to any third party, remove from view any copyright legend,
                          trademark or confidentiality notice, nor to modify, adapt, translate,
                          reverse engineer, decompile or derive the source code for Climate Coin
                          Beta.
                        </p>
                        <p className="mt-1 text-sm text-black-500">
                          <b>5. Limited Warranty and Limitation of Liability </b>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          Climate Coin Beta is provided strictly “AS IS”. Climate Coin Beta has not
                          been commercially released for sale or licensing by Climate Coin . Climate
                          Coin Beta may not operate properly and may contain errors, design flaws or
                          other problems. Climate Coin is under no obligation to release or offering
                          of the Climate Coin token. The Customer may use Climate Coin Beta only in
                          a manner consistent with the terms and conditions of this Agreement.
                          Climate Coin may, at its discretion, offer future Climate Coin tokens,
                          disable any features in Climate Coin Beta and enforce time limits on the
                          Customer’s right to use the same. In light of the fact that Climate Coin
                          Beta is provided free of charge, Climate Coin disclaims all warranties,
                          representations, and liabilities as set forth in this Agreement and
                          Climate Coin shall not be liable for damages of any kind related to the
                          Customer’s use of Climate Coin Beta.
                        </p>
                        <p className="mt-1 text-sm text-black-500">
                          <b>6. Miscellaneous </b>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <b>6.1</b> Customer may not assign or otherwise transfer any of its rights
                          under this Agreement without Climate Coin prior written consent.
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <b>6.2</b> Any dispute, controversy or claim arising out of or in
                          connection with this Agreement, or the breach, termination or invalidity
                          thereof, shall be finally settled by the Courts of Valencia, Spain. This
                          contract shall be governed by the substantive law of Spain.
                        </p>

                        <br />
                        <label>
                          <input
                            type="checkbox"
                            checked={termsChecked}
                            onChange={handleCheckboxChange}
                          />
                          &nbsp; {t('auth.terms.agreeTo')} {t('auth.terms.terms')}
                        </label>
                      </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <Button
                        type="button"
                        onClick={handleCloseModal}
                        // disabled={isLoading}
                        size="sm"
                        variant="light"
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <hr />
            {isLoading && (
              <div className="flex items-center justify-center">
                <Spinner />
              </div>
            )}
            <div className="flex flex-col gap-5 text-left">
              <Button
                type="button"
                onClick={() => {
                  if (!termsChecked) setApproveTerms(true)
                  else setMagicLogin(!isMagicLogin)
                }}
                disabled={isLoading}
                size="sm"
                variant="primary"
              >
                <div className="flex items-center justify-center">
                  <>
                    <img src={LogoMagic} className="mr-3 h-6 w-5" /> {t('auth.Login.MagicLink.go')}
                  </>
                </div>
              </Button>
              <Button
                type="button"
                onClick={() => handleExternalLogin(WalletIssuer.PERACONNECT)}
                disabled={isLoading}
                size="sm"
                variant="light"
              >
                <div className="flex items-center justify-center">
                  <>
                    <img src={allowedWallets['pera-connect'].img(false)} className="mr-2 h-6 w-6" />
                    {t('auth.Login.PeraConnect')}
                  </>
                </div>
              </Button>
            </div>
            <br />
            <div className="flex-row flexitems-center justify-center">
              {/* <div className="flexitems-center justify-center">
               */}
              <div className="flex flexitems-center justify-center">
                <label>
                  <input type="checkbox" checked={termsChecked} onChange={handleCheckboxChange} />
                  <span>
                    &nbsp; {t('auth.terms.agreeTo')}
                    <a href="#" onClick={handleButtonClick}>
                      <b>&nbsp;{t('auth.terms.terms')}</b>
                    </a>
                  </span>
                </label>
              </div>
              <div className="flex flexitems-center justify-center">
                {approveTerms && (
                  <p className="text-red-500">{t('auth.terms.pleaseAcceptTerms')}</p>
                )}
              </div>
            </div>
          </div>
        </LoginLayout>
      )
  }

  if (isMagicLogin === true) {
    return (
      <LoginLayout title={t('auth.Login.pageTitle')}>
        <div className="col-span-1 col-start-2 mx-auto max-w-screen-sm space-y-8 text-left">
          <Title size={4} as={1}>
            <div className="flex">
              <button onClick={() => setMagicLogin(!isMagicLogin)}>
                <Icon id="arrow-left-line" className="mr-4 h-8 w-8" />
              </button>
              <p>{t('auth.Login.pageTitle')}</p>
            </div>
          </Title>
          <div>
            <p className="mb-4 text-left text-xs text-neutral-4">
              {t('auth.Login.MagicLink.message')}
            </p>
          </div>
          <hr />
          <Form onSubmit={handleMagicLogin} className="flex flex-col gap-8 text-left text-xs">
            <Input
              name="email"
              type="email"
              label={t('auth.Login.form.email.label')}
              placeholder={t('auth.Login.form.email.placeholder')}
              required
            />
            <Button type="submit" disabled={isLoading} size="sm">
              <div className="flex items-center justify-center">
                <>
                  <img src={LogoMagic} className="mr-3 h-6 w-5" />
                  {t('auth.Login.MagicLink.continue')}
                </>
              </div>
            </Button>
          </Form>
        </div>
      </LoginLayout>
    )
  }
  return <></>
}
