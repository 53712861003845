import { useQuery } from 'react-query'

import { algoIndexer } from '@/lib/algoIndexer'

export interface GlobalStateKey {
  key: string
  value: {
    bytes: string
    type: number
    uint: number
  }
}

export interface AppState {
  application: {
    'created-at-round': number
    deleted: boolean
    id: number
    params: {
      'approval-program': string
      'decompiled-approval-program': string
      'clear-state-program': string
      'decompiled-clear-state-program': string
      'extra-program-pages': number
      creator: string
      'global-state': GlobalStateKey[]
      'global-state-schema': {
        'num-byte-slice': number
        'num-uint': number
      }
      'local-state-schema': {
        'num-byte-slice': number
        'num-uint': number
      }
      version: number
      'last-update-round': number
      'last-update-timestamp': number
      'last-update-txid': string
      'creation-txid': string
    }
  }
  'current-round': number
}

async function fetchAppState(): Promise<AppState> {
  return await algoIndexer.get(
    `/applications/${process.env.REACT_APP_SMART_CONTRACT_ID}?include-all=true`
  )
}

export function useAppState() {
  return useQuery(['global-app-state'], fetchAppState, {
    staleTime: 60 * 60,
  })
}
