// chainId->
// In algorand, chainId will be an string 'algorand_testnet' or 'algorand_mainnet'
// In evm networks and others, the id will be the address of an ERC20
export const assetById: any = {
  algorand_testnet: {
    160287768: {
      clawback: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ',
      creator: '73ADN7XBMBBF2DFMURORMP47LKS2HSZUHHIG4AG5PDIBCW3AFWS3I73CGQ',
      decimals: 0,
      'default-frozen': false,
      freeze: 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAY5HFKQ',
      manager: '73ADN7XBMBBF2DFMURORMP47LKS2HSZUHHIG4AG5PDIBCW3AFWS3I73CGQ',
      name: 'Climatecoin',
      'name-b64': 'Q2xpbWF0ZWNvaW4=',
      reserve: '73ADN7XBMBBF2DFMURORMP47LKS2HSZUHHIG4AG5PDIBCW3AFWS3I73CGQ',
      total: 150000000000,
      'unit-name': 'CC',
      'unit-name-b64': 'Q0M=',
    },
    10458941: {
      clawback: 'XM2W7VZODABS6RAL3FENBRKCOF6XLOQZZWIVVZTBYCVH2ADRYKN53CQLXM',
      creator: 'VETIGP3I6RCUVLVYNDW5UA2OJMXB5WP6L6HJ3RWO2R37GP4AVETICXC55I',
      decimals: 6,
      'default-frozen': false,
      freeze: 'JTDZXA72SNBU5JCFO6MI5LITSR7YDBLQI2K5LERAIIYSG4P7GZRS6ZLTJE',
      manager: '6FLEYABEB3G7ZKHL4NBCXHMMXVTEITBXI4AU3CMVXKJVEBKRRKOEY5UQEI',
      name: 'USDC',
      'name-b64': 'VVNEQw==',
      reserve: 'UJBZPEMXLD6KZOLUBUDSZ3DXECXYDADZZLBH6O7CMYXHE2PLTCW44VK5T4',
      total: 18446744073709552000n,
      'unit-name': 'USDC',
      'unit-name-b64': 'VVNEQw==',
      url: 'https://centre.io',
      'url-b64': 'aHR0cHM6Ly9jZW50cmUuaW8=',
    },
  },
}
