import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

type LabelProps = {
  name: string
  label?: string
  required?: boolean
  labelClassName?: string
}

export const Label = ({ name, label, required, labelClassName }: LabelProps) => {
  const { t } = useTranslation()

  if (!label) return null
  return (
    <label className={clsx('mb-2 text-xs text-neutral-4', labelClassName)} htmlFor={name} id={name}>
      {label}
      {!required && ` ${t('label.text.optional')}`}
    </label>
  )
}
