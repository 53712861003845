import algosdk from 'algosdk'
import { Buffer } from 'buffer'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { httpClient } from '@/lib/httpClient'
import { queryClient } from '@/lib/react-query'
import { sessionWallet } from '@/lib/sessionWallet'

import { CertificateClaimTxns, Compensation, compensationKeys } from '../types'

async function handleClaimCertificate({
  compensationId,
  encodedOptinTxn,
  encodedSendTxn,
}: CertificateClaimTxns): Promise<Compensation> {
  if (!sessionWallet) throw Error('[handleClaimCertificate] !sessionWallet')
  // convert the txns to buffers
  const optinTxnBuffer = Buffer.from(Object.values(encodedOptinTxn))
  const sendTxnBuffer = Buffer.from(Object.values(encodedSendTxn))

  // skip this in testing
  if (process.env.NODE_ENV === 'test') {
    return httpClient.post(`/compensations/${compensationId}/claim/certificate`, {
      signedTxn: [],
    })
  }

  const optinTxn = algosdk.decodeUnsignedTransaction(optinTxnBuffer)
  const sendTxn = algosdk.decodeUnsignedTransaction(sendTxnBuffer)

  // decode and sign
  const signedTxnObj = await sessionWallet.signTxn([optinTxn, sendTxn])
  if (!signedTxnObj) return Promise.reject('Transaction not signed')
  const signedTxn = signedTxnObj.map((stxn) => stxn.blob)

  return httpClient.post(`/compensations/${compensationId}/claim/certificate`, {
    signedTxn,
  })
}

export function useClaimCertificate() {
  return useMutation((claimTxns: CertificateClaimTxns) => handleClaimCertificate(claimTxns), {
    onSuccess: () => {
      queryClient.invalidateQueries(compensationKeys.all)
      toast.success('Certificate NFT claimed successfully')
    },
    onError: () => {
      toast.error('Error claiming the certificate')
    },
  })
}
