import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'

import { Card } from '@/componentes/Card/Card'
import { MainLayout } from '@/componentes/Layout/MainLayout'

import { useAppState } from './api/useAppState'
import { useDocumentsCount } from './api/useDocumentsCount'
import { useGeneralStats } from './api/useGeneralStats'

function formatNumber(num: number) {
  if (Math.abs(num) >= 1000000000) {
    return Math.sign(num) * (Math.abs(num) / 1000000000) + 'B'
  }
  if (Math.abs(num) >= 1000000) {
    return Math.sign(num) * (Math.abs(num) / 1000000) + 'M'
  }
  if (Math.abs(num) >= 1000) {
    return Math.sign(num) * (Math.abs(num) / 1000) + 'K'
  }
  return Math.sign(num) * Math.abs(num)
}

export const GeneralStats = () => {
  const generalS = useGeneralStats()
  const appState = useAppState()
  const projectsCount = useDocumentsCount()
  const { t } = useTranslation()

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])

  const totalSupply = generalS?.data?.asset.params.total
  // circulating supply is never returned!
  // const circulatingSupply = generalS?.data?.asset.params['circulating-supply'] ?? 0

  const totalCoinsIssued =
    appState?.data?.application.params['global-state'].find((gstate) => {
      return gstate.key === 'dG90YWxfY29pbnNfaXNzdWVk'
    })?.value.uint ?? 0

  //  const totalCoinBurned = totalCoinsIssued - circulatingSupply

  const totalNftsIssued =
    appState?.data?.application.params['global-state'].find((gstate) => {
      return gstate.key === 'dG90YWxfbmZ0X2lzc3VlZA=='
    })?.value.uint ?? 0
  const totalNftsBurned =
    appState?.data?.application.params['global-state'].find((gstate) => {
      return gstate.key === 'dG90YWxfbmZ0X2J1cm5lZA=='
    })?.value.uint ?? 0

  const currentNftsSupply = totalNftsIssued - totalNftsBurned
  const totalNftsCirculating = totalCoinsIssued - totalNftsBurned

  return (
    <MainLayout title="Platform Stats">
      <div className="flex h-full items-center justify-center">
        <div className="flex flex-col justify-center">
          <h1 className="my-8 ml-8 text-left text-[32px] font-bold">Platform Stats</h1>
          <div className="m-auto flex w-[90%] flex-wrap justify-center gap-5">
            <div className="h-[170px] w-[270px]">
              <Card heightFull padding="m">
                <p className="mb-2 text-md font-medium">{t('generalStats.totalSupply')}</p>
                <p className="text-sm text-neutral-4">{t('generalStats.totalSupplyDescr')}</p>
                <p className="text-4xl font-medium">{totalSupply && formatNumber(totalSupply)}</p>
              </Card>
            </div>
            <div className="h-[170px] w-[270px]">
              <Card heightFull padding="m">
                <p className="mb-2 text-md font-medium">{t('generalStats.currentlyCirculating')}</p>
                <p className="text-sm text-neutral-4">
                  {t('generalStats.currentlyCirculatingDescr')}
                </p>
                <p className="text-4xl font-medium">{formatNumber(totalNftsCirculating)}</p>
              </Card>
            </div>
            <div className="h-[170px] w-[270px]">
              <Card heightFull padding="m">
                <p className="mb-2 text-md font-medium">{t('generalStats.totalIssued')}</p>
                <p className="text-sm text-neutral-4">{t('generalStats.totalIssuedDescr')}</p>
                <p className="text-4xl font-medium">{formatNumber(totalCoinsIssued)}</p>
              </Card>
            </div>
            <div className="h-[170px] w-[270px]">
              <Card heightFull padding="m">
                <p className="mb-2 text-md font-medium">{t('generalStats.totalBurned')}</p>
                <p className="text-sm text-neutral-4">{t('generalStats.totalBurnedDescr')}</p>
                <p className="text-4xl font-medium text-primary-brightGreen">
                  {formatNumber(totalNftsBurned)}
                </p>
              </Card>
            </div>
            <div className="h-[170px] w-[270px]">
              <Card heightFull padding="m">
                <p className="mb-2 text-md font-medium">{t('generalStats.totalProjects')}</p>
                <p className="text-sm text-neutral-4">{t('generalStats.totalProjectsDescr')}</p>
                <p className="text-4xl font-medium">{(projectsCount.data as number) || 0}</p>
              </Card>
            </div>
            <div className="h-[170px] w-[270px]">
              <Card heightFull padding="m">
                <p className="mb-2 text-md font-medium">{t('generalStats.NFTSupply')}</p>
                <p className="text-sm text-neutral-4">{t('generalStats.NFTSupplyDescr')}</p>
                <p className="text-4xl font-medium">{formatNumber(currentNftsSupply)}</p>
              </Card>
            </div>
            <div className="h-[170px] w-[270px]">
              <Card heightFull padding="m">
                <p className="mb-2 text-md font-medium">{t('generalStats.totalNFTCirculating')}</p>
                <p className="text-sm text-neutral-4">
                  {t('generalStats.totalNFTCirculatingDescr')}
                </p>
                <p className="text-4xl font-medium">{formatNumber(totalNftsCirculating)}</p>
              </Card>
            </div>
            <div className="h-[170px] w-[270px]">
              <Card heightFull padding="m">
                <p className="mb-2 text-md font-medium">{t('generalStats.NFTIssuedBurned')}</p>
                <p className="text-sm text-neutral-4">{t('generalStats.NFTIssuedBurnedDescr')}</p>
                <p className="text-4xl font-medium leading-none text-primary-brightGreen">
                  {formatNumber(totalNftsIssued)} / {formatNumber(totalNftsBurned)}
                </p>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
