// import algosdk from 'algosdk';
import algosdk from 'algosdk'
import { Buffer } from 'buffer'
import i18n from 'i18next'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { AuthProviderConfig, initReactQueryAuth } from 'react-query-auth'
import { toast } from 'react-toastify'

import { SessionWallet } from '@/algorand-session-wallet'
import { Spinner } from '@/componentes/Elements/Spinner/Spinner'
import {
  AuthUser,
  ChallengeResponse,
  getUser,
  LoginCredentialsDTO,
  RegisterCredentialsDTO,
  UserResponse,
} from '@/features/auth'
import { httpClient } from '@/lib/httpClient'
import { queryClient } from '@/lib/react-query'
import { removeSessionWallet, sessionWallet, setSessionWallet } from '@/lib/sessionWallet'
import storage from '@/utils/storage'

function isJwtExpired(token: string) {
  let isJwtExpired = false
  const { exp }: JwtPayload = jwtDecode(token)
  const currentTime = new Date().getTime() / 1000

  if (currentTime > exp!) isJwtExpired = true

  return isJwtExpired
}

async function loadUser(): Promise<AuthUser | null> {
  const localJWT = storage.getToken()
  if (process.env.NODE_ENV === 'test') return { name: 'Fernando' } as AuthUser
  if (!localJWT || isJwtExpired(localJWT)) return null

  const userMetadata = await getUser()

  if (!sessionWallet || sessionWallet.walletName !== userMetadata.issuer) {
    if (!userMetadata.issuer) throw Error('[auth] loadUser !userMetada.issuer')
    if (!userMetadata.email) throw Error('[auth] loadUser !userMetada.issuer')
    setSessionWallet(
      new SessionWallet(
        'TestNet',
        userMetadata.issuer,
        userMetadata.email || '',
        process.env.REACT_APP_MAGICLINK_PUBLIC as string,
        process.env.REACT_APP_ALGORAND_RPC_URL as string
      )
    )
  }

  await i18n.changeLanguage(userMetadata.language)

  return userMetadata
}

async function loginFn(data: LoginCredentialsDTO): Promise<AuthUser | null> {
  setSessionWallet(
    new SessionWallet(
      'TestNet',
      data.issuer,
      data.email || '',
      process.env.REACT_APP_MAGICLINK_PUBLIC as string,
      process.env.REACT_APP_ALGORAND_RPC_URL as string
    )
  )
  if (!sessionWallet) throw Error('[loginFn] !sessionWallet')
  await sessionWallet.connect()

  let challenge: ChallengeResponse
  try {
    challenge = await httpClient.get(
      `/web3-auth/challenge/${await sessionWallet.getDefaultAccount()}`
    )
  } catch (e) {
    console.error('[loginFn] Failed challenge endpoint, e:', e)
    console.error('[loginFn] retrying challenge endpoint...')
    challenge = await httpClient.get(
      `/web3-auth/challenge/${await sessionWallet.getDefaultAccount()}`
    )
    toast.error('Failed login, please try again')
  }

  const { challengeTxn } = challenge
  const rawTxnChallenge = Buffer.from(Object.values(challengeTxn))
  const unsignedTxn = algosdk.decodeUnsignedTransaction(rawTxnChallenge)
  const signedTxns = await sessionWallet.signTxn([unsignedTxn], false)
  const signedTxn = signedTxns[0]

  const response: UserResponse = await httpClient.post(`/web3-auth/login`, {
    challengeTxn: signedTxn,
    issuer: data.issuer,
    email: data.email,
  })
  storage.setToken(response.jwt as string)
  await i18n.changeLanguage(response.user.language)

  return response.user
}

export async function logoutFn() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (sessionWallet) {
    await sessionWallet.disconnect()
    removeSessionWallet()
  }
  storage.clear()
  window.location.assign(window.location.origin as unknown as string)
  await queryClient.resetQueries()
}

const authConfig: Partial<AuthProviderConfig<AuthUser | null, unknown>> = {
  loadUser,
  loginFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Spinner size="xl" />
      </div>
    )
  },
  waitInitial: process.env.NODE_ENV !== 'test',
}

export const { AuthProvider, useAuth } = initReactQueryAuth<
  AuthUser | null,
  unknown,
  LoginCredentialsDTO,
  RegisterCredentialsDTO
>(authConfig as AuthProviderConfig<AuthUser | null, unknown>)
